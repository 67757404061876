import Component from '../../core/component';
import FicheStore from '../../store/ficheStore';

const FIELDS = [
    "name",
    "postal_code",
    "departement",
    "commune",
    "region"
];

class SearchComponent extends Component {

    constructor(element) {
        super(element);

        //elements
        this.input = this.element.querySelector('input');

        if(window.location.search && ~window.location.search.indexOf("search")) {
            FicheStore.once('change:fiches', this.handleQuery.bind(this));
        }

        this.attachEvents();
    }

    attachEvents() {
        this.input.addEventListener('input', this.handleInput.bind(this), false);
    }

    handleInput() {
        const value    = this.input.value.toLowerCase();
        const intValue = parseInt(value, 10);

        if(value === "") {
            return FicheStore.REMOVE_FILTER('search');
        }

        FicheStore.SET_FILTER('search', (fiche) => {
            let res = value === "";

            FIELDS.forEach((field) => {
                if(fiche[field] && (~fiche[field].toLowerCase().indexOf(value) || (intValue && ~fiche[field].indexOf(intValue)))) res = true;
            });

            return res;
        });
    }

    handleQuery() {
        const query = /^\?(.*)=(.*)/i.exec(window.location.search);

        if(query && query[1] && query[2] && ~["s", "q", "search"].indexOf(query[1]) && query[2] != null && query[2] != "") {
            const value    = query[2].toLowerCase();
            const intValue = parseInt(value, 10);

            this.input.value = query[2];

            this.waitForGoogle()
                .then(() => {
                    FicheStore.setState('autocomplete', false);

                    FicheStore.SET_FILTER('search', (fiche) => {
                        let res = value === "";

                        FIELDS.forEach((field) => {
                            if(fiche[field] && (~fiche[field].toLowerCase().indexOf(value) || (intValue && ~fiche[field].indexOf(intValue)))) res = true;
                        });

                        return res;
                    });

                    FicheStore.setState('autocomplete', true);
                });
        }
    }

    waitForGoogle() {
        return new Promise((resolve) => {
            this.timer = setInterval(() => {
                if(window.google) {
                    clearInterval(this.timer);
                    return resolve();
                }
            }, 10);
        });
    }

}

Component.register('.filter-search', SearchComponent);

export default SearchComponent;