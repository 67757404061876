export function update(action, input) {
    return new Promise(function(resolve, reject) {
        let data     = {};

        if(input.file) {
            data['hasFile']  = input.name;
            data['fileName'] = input.fileName;
            data[input.name] = input.file;
        }
        else {
            data[input.name] = sanitize(input);
        }

        if(input && input.ids) {
          data = input;
        }

        fetch(action, {
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            method: 'PUT',
            credentials: 'include'
        })
            .then(function(res) { return res.json(); })
            .then(function(res) {
                console.log(res);
                if(!res.id && !res.done) return;
                return resolve(res);
            })
            .catch(reject);
    });
}

export function upload(action, input) {
    return new Promise(function(resolve, reject) {
        const file = input.files[0];

        let form = new FormData();
            form.append(input.name, file);

        const req = new XMLHttpRequest();

        req.onload = function(e) {
            return resolve(JSON.parse(req.responseText));
        };

        req.open('POST', action, true);
        req.send(form);
    });
}

export function removeUpload(action) {
  return new Promise(function(resolve, reject) {
    fetch(action, {
      headers: {
        "Content-Type": "application/json"
      },
      method: 'DELETE',
      credentials: 'include'
    })
      .then(function(res) { return res.json(); })
      .then(function(res) {
        return resolve(res);
      });
  });
}

export function create(action, data) {
    return new Promise(function(resolve, reject) {
        fetch(action, {
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            },
            method: "POST",
            credentials: "include"
        })
            .then(function(res) { return res.json(); })
            .then(function(res) {
                return resolve(res);
            });
    });
}

export function remove(action, data) {
    return new Promise(function(resolve, reject) {
        fetch(action, {
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            },
            method: 'DELETE',
            credentials: 'include'
        })
            .then(function(res) { return res.json(); })
            .then(function(res) {
                return resolve(res);
            });
    });
}

export function all() {
    return new Promise((resolve, reject) => {
        fetch('/api/fiches')
            .then(res => res.json())
            .then(res => {
                return resolve(res.map((item) => {
                    if(item.region) item.region = item.region.region;
                    if(item.departement) item.departement = item.departement.departement;

                    return item;
                }));
            })
            .catch(reject)
    });
}

function sanitize(input) {
    if(input.nodeName === "TEXTAREA")
        return input.value.trim();
    else if(input.type === 'checkbox' || input.type === 'radio')
        return input.checked;
    else
        return input.value;
}