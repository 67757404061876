import EventEmitter from '../core/eventEmitter';
import { all } from './api';

class FicheStore extends EventEmitter {

    constructor() {
        super();

        this.state = {
            fiches: [],
            filtered: [],
            filters: {}
        };
    }

    getState(key = null) {
        if(key) return this.state[key];

        return this.state;
    }

    setState(key, newState) {
        this.state[key] = newState;

        this.emit(`change:${key}`, this.state[key]);
    }

    GET_ALL() {
        all()
            .then((res) => {
                this.state.filtered = res;

                this.setState('fiches', res);
            });
    }

    UPDATE_FILTERS() {
        let res = this.state.fiches;

        for(var name in this.state.filters) {
            res = res.filter(this.state.filters[name]);
        }

        this.setState('filtered', res);
    }

    SET_FILTER(name, filter) {
        this.state.filters[name] = filter;

        this.UPDATE_FILTERS();
    }

    REMOVE_FILTER(name) {
        if(this.state.filters[name]) delete this.state.filters[name];

        this.UPDATE_FILTERS();
    }

}

export default new FicheStore();