import Component from '../../core/component';
import FicheStore from '../../store/ficheStore';

class ServiceComponent extends Component {

    constructor(element) {
        super(element);

        this.services = Array.from(this.element.querySelectorAll('.service-filter'));

        this.attachEvents();
    }

    attachEvents() {
        this.services.forEach((service) => {
            service.addEventListener('click', this.handleClick.bind(this), true);
        });
    }

    handleClick(event) {
        let service   = event.target;

        while(!service.classList.contains('service-filter')) service = service.parentNode;

        //Toggle back the service
        if(service.classList.contains('is-selected')) {
            FicheStore.REMOVE_FILTER('services');
            service.classList.remove('is-selected');

            return;
        }

        const serviceId = parseInt(service.getAttribute('data-id'), 10);

        this.services.forEach((item) => {
            if(item == service) {
                item.classList.add('is-selected');
            }
            else {
                item.classList.remove('is-selected');
            }
        });

        FicheStore.SET_FILTER('services', (fiche) => {
            return fiche.services.some(service => service.id === serviceId);
        });
    }

}

Component.register('.services-filter', ServiceComponent);

export default ServiceComponent;