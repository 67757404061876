import Component from '../../core/component';
import FicheStore from '../../store/ficheStore';

class FicheItem extends Component {

    constructor(element) {
        super(element);

        this.id = this.element.id || parseInt(this.element.getAttribute('data-id'), 10);
    }

    static template(fiche, index) {
        let ficheElement = document.createElement('li');
            ficheElement.classList.add('fiche-item');
            ficheElement.setAttribute('data-id', fiche.id);
            ficheElement.id = fiche.id;

        ficheElement.innerHTML = `
            <div class="fiche-main">
                <a href="/fiches/${fiche.slug}" class="fiche-link"></a>
                <h2 class="fiche-name">${fiche.name}</h2>
                
                <ul class="fiche-services">
                    ${fiche.services.map(service => `
                        <li class="fiche-service" style="background-color: ${service.color}">
                            ${service.name}
                        </li>
                    `).join('')}
                </ul>
                
                <a href="/fiches/${fiche.slug}" class="fiche-go">Plus de détails</a>
            </div>
            <ul class="fiche-infos">
                ${fiche.tel ? `<li class="fiche-info">
                    <i class="icon-phone info-name"></i>
                    <a href="tel:${fiche.tel}" class="info-value">${fiche.tel}</a>
                </li>` : ''}
                ${fiche.fax ? `<li class="fiche-info">
                    <i class="icon-fax info-name"></i>
                    <span class="info-value">${fiche.fax}</span>
                </li>` : ''}
                ${fiche.mail ? `<li class="fiche-info">
                    <i class="icon-mail info-name"></i>
                    <a  href="mailto:${fiche.mail}" class="info-value">${fiche.mail}</a>
                </li>` : ''}
                ${fiche.url ? `<li class="fiche-info">
                    <i class="icon-web info-name"></i>
                    <a href="${fiche.web}" target="_blank" class="info-value">${fiche.web}</a>
                </li>` : ''}
                ${fiche.postal_code || fiche.commune ? `
                <li class="fiche-info fiche-address">
                    ${fiche.commune ? `<span class="address-ville">${fiche.commune}</span>` : ''}
                    ${fiche.postal_code ? `<span class="address-cp">${fiche.postal_code}</span>` : ''}
                </li>` : ''}
            </ul>
        `;

        let ficheMap = document.createElement('div');
            ficheMap.classList.add('fiche-map');
            ficheMap.setAttribute('data-static', '');
            ficheMap.setAttribute('data-lat', fiche.lat);
            ficheMap.setAttribute('data-lng', fiche.lng);
            ficheMap.setAttribute('data-marker', index);

        requestAnimationFrame(() => {
            ficheElement.insertBefore(ficheMap, ficheElement.firstChild);
        });

        return ficheElement;
    }

}

Component.register('.fiche-item', FicheItem);

export default FicheItem;