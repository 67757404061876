import ComponentList from './componentList';

class Component {

    constructor(element) {
        this.element = element[0] ? element[0] : element;
    }

    static register(name, component) {
        ComponentList.register(name, component);
    }

}

export default Component;