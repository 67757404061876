import Component  from '../../core/component';
import {lighten} from "../../utils/color";
import FicheStore from '../../store/ficheStore';

class ColorComponent extends Component {

  constructor(element) {
    super(element);

    this.color    = element.getAttribute('data-color');
    this.isActive = false;
    this.isHover  = false;

    this.attachEvents();
  }

  attachEvents() {
    FicheStore.on('change:filtered', this.update.bind(this));

    this.element.addEventListener('mouseover', this.over.bind(this), false);
    this.element.addEventListener('mouseleave', this.out.bind(this), false);
  }

  over() {
    this.isHover = true;

    this.render();
  }

  out() {
    this.isHover = false;

    this.render();
  }

  update() {
    //To ensure the parent is disabled/enabled
    requestAnimationFrame(() => {
      this.isActive = this.element.parentNode.classList.contains('is-selected');

      this.render();
    });
  }

  render() {
    const color = this.isHover || this.isActive ? lighten(this.color, 10) : this.color;

    this.element.style.backgroundColor = color;
  }

}

Component.register('[data-color]', ColorComponent);

export default ColorComponent;