import Component  from '../../core/component';
import FicheStore from '../../store/ficheStore';

const IS_SAFARI  = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);

class MainComponent extends Component {

    constructor(element) {
        super(element);

        if(!(window.top != window.self)) return;

        document.body.classList.add('is-iframe');

        console.log('iframe');

        this.attachEvents();
    }

    attachEvents() {
        window.addEventListener("message", this.handleMessage.bind(this), false);
        window.addEventListener("unload", this.handleUnload.bind(this), false);

        FicheStore.on('render', this.handleHeightUpdate.bind(this));
        //FicheStore.on('change:pagination', this.handleHeightUpdate.bind(this));
    }

    handleMessage(event) {
        if(event.data === "sizes") {
            this.event = event;

            setTimeout(() => {
                let height = 80;
                for(var i = 0; i < document.body.childNodes.length; i++) height += document.body.childNodes[i].clientHeight || 0;

                event.source.postMessage({
                    height: height,
                    width:  document.body.scrollWidth
                }, event.origin);
            }, 500);
        }
    }

    handleHeightUpdate() {
        if(!this.event) return;

        console.log('height update');

        let height = 80;

        for(let i = 0; i < document.body.childNodes.length; i++) height += document.body.childNodes[i].clientHeight || 0;

        this.event.source.postMessage({
            height: height,
            width:  document.body.scrollWidth
        }, this.event.origin);
    }

    handleUnload() {
        this.event.source.postMessage('unload', this.event.origin);
    }

}

Component.register('main', MainComponent);

export default MainComponent;