import Component from '../../core/component';
import FicheStore from '../../store/ficheStore';

const FIELDS = [
    "name",
    "postal_code",
    "departement",
    "commune",
    "region"
];

const NAMES = {
    "name" : "Nom",
    "postal_code": "Ville",
    "commune": "Ville",
    "region": "Région",
    "departement": "Département"
};

class AutocompleteComponent extends Component {

    constructor(element) {
        super(element);

        //elements
        this.input = document.querySelector('input[name="search"]');

        //values
        this.index = -1;

        this.attachEvents();
    }

    attachEvents() {
        this.input.addEventListener('keydown', this.handleKey.bind(this), false);
        this.element.addEventListener('click', this.handleClick.bind(this), false);

        FicheStore.on('change:filtered', this.render.bind(this));
    }

    handleKey(event) {
        //enter
        if(event.keyCode == 13) {
            event.preventDefault();
            this.handleEnter();
        }
        //up
        else if(event.keyCode == 38) {
            this.setIndex(-1);
        }
        //down
        else if(event.keyCode == 40) {
            this.setIndex(+1);
        }
    }

    setIndex(diff) {
        this.index += diff;

        if(this.index < 0) this.index = 0;

        const items = Array.from(this.element.querySelectorAll('.autocomplete-value'));

        if(this.index > items.length - 1) this.index = items.length - 1;

        items.forEach((item, index) => {
            if(index < this.index || index > this.index) {
                item.classList.remove('is-selected');
            }
            else {
                item.classList.add('is-selected');
            }
        });
    }

    handleEnter() {
        const items = Array.from(this.element.querySelectorAll('.autocomplete-value'));
        const item  = items[this.index];

        this.input.value = item.getAttribute('data-value');
        this.input.dispatchEvent(new CustomEvent("input", { target: this.input }));

        //clean
        this.element.innerHTML = '';
        this.index = -1;
    }

    handleClick(event) {
        let item = event.target;

        while(!item.classList.contains('autocomplete-value')) item = item.parentNode;

        this.input.value = item.getAttribute('data-value');
        this.input.dispatchEvent(new CustomEvent("input", { target: this.input }));

        //clean
        this.element.innerHTML = '';
        this.index = -1;
    }

    render(fiches) {
        if(FicheStore.getState('autocomplete') === false) return;

        const value = this.input.value.toLowerCase();
        let html = '';
        let data = {};

        if(value.length < 2) {
            this.index = -1;
            return this.element.innerHTML = '';
        }

        FIELDS.forEach((field) => {
            data[field] = [];
        });

        fiches.forEach((fiche) => {
            FIELDS.forEach((field) => {
                if(fiche[field] && ~fiche[field].toLowerCase().indexOf(value) && data[field].indexOf(fiche[field]) == -1) {
                    data[field].push(fiche[field]);
                }
            });
        });

        for(let key in data) {
            if(data[key].length) {
                html += '<li class="autocomplete-group"><ul class="autocomplete-list">';

                data[key].forEach((value) => {
                    html += `
                    <li class="autocomplete-value" data-value="${value}">
                        <span class="group-name">${NAMES[key]}</span> : <span class="group-value">${value}</span>
                    </li>
                `;
                });

                html += '</ul></li>';
            }
        }

        this.element.innerHTML = html;
    }

}

Component.register('.search-autocomplete', AutocompleteComponent);

export default AutocompleteComponent;