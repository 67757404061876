import Component from '../../core/component';
import GoogleMapsLoader from 'google-maps';
import FicheStore from '../../store/ficheStore';

GoogleMapsLoader.KEY = 'AIzaSyC8_P0da1DfkDcgabFImKbOeqUaeN43chk';

const OPTIONS = {
    zoom: 7,
    minZoom: 5,
    scrollwheel: false,
    mapTypeControl: false,

    styles: [
        {
            featureType: "poi",
            elementType: "labels",
            stylers: [
                { visibility: "off" }
            ]
        }
    ]
};

class MapComponent extends Component {

    constructor(element) {
        super(element);

        this.map     = null;
        this.markers = [];
        this.infos   = [];

        this.fiches = FicheStore.state.fiches;
        this.lat    = this.element.getAttribute('data-lat');
        this.lng    = this.element.getAttribute('data-lng');
        this.zoom   = parseInt(this.element.getAttribute('data-zoom'), 10);
        this.type   = this.element.getAttribute('data-type');

        this.type = this.type ? this.type.toUpperCase() : "ROADMAP";

        this.attachEvents();
    }

    attachEvents() {
        FicheStore.on('change:fiches', this.init.bind(this));
        FicheStore.on('change:filtered', this.render.bind(this));

        FicheStore.on('marker:over', this.handleOver.bind(this));
        FicheStore.on('marker:out', this.handleOut.bind(this));
    }

    init() {
        this.fiches = FicheStore.getState('filtered');

        GoogleMapsLoader.load((google) => {
            this.options = Object.assign({
                mapTypeId: google.maps.MapTypeId[this.type],
                center:    new google.maps.LatLng(this.lat, this.lng)
            }, OPTIONS);

            if(this.zoom) this.options.zoom = this.zoom;

            this.map = new google.maps.Map(this.element, this.options);

            this.render();
        });
    }

    handleOver(index) {
        index -= 1;

        if(!this.markers[index]) return;

        this.markers[index].setAnimation(google.maps.Animation.BOUNCE);
        console.log(index);
    }

    handleOut(index) {
        index -= 1;

        if(!this.markers[index]) return;

        this.markers[index].setAnimation(null);
    }

    render() {
        this.fiches = FicheStore.getState('filtered');

        this.markers.forEach((marker) => {
            marker.setMap(null);
        });

        this.fiches.forEach((fiche, index) => {
            let findInMarkers = false;

            //update an existing marker
            this.markers.forEach((marker) => {
                if(marker.title == fiche.name) {
                    marker.setMap(this.map);

                    findInMarkers = true;
                }
            });

            if(findInMarkers) return;

            //or create the marker
            const markerOptions = {
                position:   new google.maps.LatLng(fiche.lat, fiche.lng),
                title:      fiche.name,
                icon:       `https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_red${(index+1)}.png`,
                animation:  google.maps.Animation.DROP
            };

            const content = `
                <div class="fiche-map-info">
                    <h2 class="fiche-name">${fiche.name}</h2>
                    
                    <ul class="fiche-infos">
                        ${fiche.tel ? `
                        <li class="fiche-info">
                            <i class="icon-phone"></i>
                            <a href="tel:${fiche.tel}" class="info-value">${fiche.tel}</a>
                        </li>` : ''}
                        ${fiche.mail ? `
                        <li class="fiche-info">
                            <i class="icon-mail"></i>
                            <a  href="mailto:${fiche.mail}" class="info-value">${fiche.mail}</a>
                        </li>` : ''}
                        ${fiche.web ? `
                        <li class="fiche-info">
                            <i class="icon-web"></i>
                            <a href="${fiche.web}" target="_blank" class="info-value ellipsis">${fiche.web}</a>
                        </li>` : ''}
                        ${fiche.facebook ? `
                        <li class="fiche-info">
                            <i class="icon-facebook"></i>
                            <a href="${fiche.facebook}" target="_blank" class="info-value ellipsis">${fiche.facebook}</a>
                        </li>` : ''}
                        ${fiche.twitter ? `
                        <li class="fiche-info">
                            <i class="icon-twitter"></i>
                            <a href="${fiche.twitter}" target="_blank" class="info-value ellipsis">${fiche.twitter}</a>
                        </li>` : ''}
                    </ul>
                    
                    ${fiche.postal_code || fiche.commune ? `
                    <div class="fiche-address">
                        ${fiche.commune ? `<span class="address-ville">${fiche.commune}</span>` : ''}
                        ${fiche.postal_code ? `<span class="address-cp">${fiche.postal_code}</span>` : ''}
                    </div>` : ''}
                    
                    <a href="/fiches/${fiche.slug}" class="fiche-link">Voir l'agence</a>
                </div>
            `;

            let marker = new google.maps.Marker(markerOptions);
            let info   = new google.maps.InfoWindow({ content });

            marker.addListener('click', () => {
                if(this.openInfo) {
                    this.openInfo.close();
                    this.openInfo = null;
                }

                info.open(this.map, marker);

                this.openInfo = info;
            });

            marker.addListener('mouseover', () => {
                if(this.openInfo) {
                    this.openInfo.close();
                    this.openInfo = null;
                }

                info.open(this.map, marker);

                this.openInfo = info;
            });

            marker.setMap(this.map);

            this.markers.push(marker);
        });
    }

}

Component.register('.map-filter', MapComponent);

export default MapComponent;