import Component from '../../core/component';
import FicheStore from '../../store/ficheStore';
import Item       from './item';

class FicheList extends Component {

    constructor(element) {
        super(element);

        this.fiches = FicheStore.state.fiches;

        FicheStore.GET_ALL();
        FicheStore.on('change:fiches', this.init.bind(this));
        FicheStore.on('change:filtered', this.render.bind(this));
    }

    init() {
        this.fiches = FicheStore.getState('filtered');

        this.fiches.forEach((fiche, index) => {
            this.element.appendChild(Item.template(fiche, index+1));
        });
    }

    render() {
        this.fiches = FicheStore.getState('filtered');
        this.ids    = this.fiches.map(fiche => fiche.id);

        let items   = Array.from(this.element.querySelectorAll('.fiche-item'));

        //get the actual to hide
        items.forEach((item) => {
            const id = parseInt(item.id, 10) || parseInt(item.getAttribute('data-id'), 10);

            if(~this.ids.indexOf(id)) {
                requestAnimationFrame(() => {
                    item.classList.remove('is-hidden');
                });
            }
            else {
                requestAnimationFrame(() => {
                    item.classList.add('is-hidden');
                });
            }
        });

        requestAnimationFrame(() => FicheStore.emit('render'));
    }

}

Component.register('.fiche-list', FicheList);

export default FicheList;