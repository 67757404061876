import ComponentList from './core/componentList';

//import components for the front-end
import MainComponent from './components/main/main';
import Color         from './components/core/color';

import ficheItem from './components/fiche/item';
import ficheMap  from './components/fiche/map';
import ficheList from './components/fiche/list';
import fichePagination from './components/fiche/pagination';

import MapFilter from './components/filter/map';
import SearchFilter from './components/filter/search';
import Autocomplete from './components/filter/autocomplete';
import ServiceFilter from './components/filter/services';
import Localization from './components/filter/localization';

console.log('front ready');