import Component  from '../../core/component';
import FicheStore from '../../store/ficheStore';

const FICHES_PER_PAGE = 10;

class FichePagination extends Component {

    constructor(element) {
        super(element);

        this.fiches = FicheStore.state.fiches;

        this.reset();

        FicheStore.on('change:fiches', this.render.bind(this));
        FicheStore.on('change:filtered', this.render.bind(this));
    }

    reset() {
        this.page  = 0;
        this.start = 0;
        this.end   = FICHES_PER_PAGE;
    }

    render() {
        let fiches = FicheStore.getState('filtered');

        if(fiches.length != this.fiches.length) {
            this.reset();
        }

        this.fiches = fiches;

        let page  = 0;
        let pluck = this.fiches.slice(this.start, this.end).map(fiche => fiche.id);

        this.fiches.forEach((fiche) => {
           if(~pluck.indexOf(fiche.id)) {
                document.querySelector(`.fiche-item[data-id="${fiche.id}"]`).classList.remove('is-out-of-page');
            }
            else {
                document.querySelector(`.fiche-item[data-id="${fiche.id}"]`).classList.add('is-out-of-page');
            }
        });

        this.element.innerHTML = '';

        for(let i = 0; i < this.fiches.length; i += FICHES_PER_PAGE) {
            page++;

            this.element.appendChild(this.template(page));
        }
    }

    handleClick(event) {
        this.page = parseInt(event.target.getAttribute('data-page'), 10) - 1;

        this.start = this.page * FICHES_PER_PAGE;
        this.end   = this.start + FICHES_PER_PAGE;

        this.render();

        requestAnimationFrame(() => FicheStore.emit('render'));
    }

    template(index) {
        let li = document.createElement('li');

        li.classList.add('page');
        li.setAttribute('data-page', index);
        li.addEventListener('click', this.handleClick.bind(this), false);

        if((this.page + 1) == index) li.classList.add('is-active');

        li.innerHTML = index;

        return li;
    }

}

Component.register('.fiche-pagination', FichePagination);

export default FichePagination;