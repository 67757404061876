import Component from '../../core/component';
import FicheStore from '../../store/ficheStore';

const KEY = 'AIzaSyC8_P0da1DfkDcgabFImKbOeqUaeN43chk';
const IMAGE_ERROR = '/img/map_error.png';

class FicheMap extends Component {

    constructor(element) {
        super(element);

        this.lat = this.element.getAttribute('data-lat');
        this.lng = this.element.getAttribute('data-lng');
        this.marker   = this.element.getAttribute('data-marker');
        this.isStatic = this.element.hasAttribute('data-static');
        this.index    = this.element.getAttribute('data-index');

        this.render();
        this.attachEvents();
    }

    attachEvents() {
        this.element.addEventListener('mouseover', this.handleOver.bind(this), false);
        this.element.addEventListener('mouseout', this.handleOut.bind(this), false);
    }

    handleOver() {
        FicheStore.emit('marker:over', this.marker);
    }

    handleOut() {
        FicheStore.emit('marker:out', this.marker);
    }

    render() {
        var parent = this.element.parentNode;
        var mesureElement = this.element;

        if(document.querySelector('.fiche-item')) {
            while(!parent.classList.contains('fiche-item')) parent = parent.parentNode;

            //prevent error
            if(parent.classList.contains('is-out-of-page')) {
                mesureElement = document.querySelector('.fiche-item:not(.is-out-of-page) .fiche-map');
            }
        }


        const sizeEl = mesureElement.getBoundingClientRect();
        const size = {
            width: Math.round(sizeEl.width),
            height: Math.round(sizeEl.height)
        };

        let src = `https://maps.googleapis.com/maps/api/staticmap?center=${this.lat},${this.lng}&zoom=8&size=${size.width}x${size.height}&maptype=roadmap&key=${KEY}&markers=color:red%7Clabel:${this.marker}%7C${this.lat},${this.lng}`;

        const imgTest = document.createElement('img');

        imgTest.onload = () => {
            this.element.style.backgroundImage = `url(${src})`;
        };

        imgTest.onerror = () => {
            this.element.style.backgroundImage = `url(${IMAGE_ERROR})`;
        };

        imgTest.src = src;

        this.element.onerror = (err) => {
            console.log(err);
        };
    }

}

Component.register('.fiche-map', FicheMap);

export default FicheMap;