import Component from '../../core/component';
import FicheStore from '../../store/ficheStore';

const FIELDS = [
    "name",
    "postal_code",
    "departement",
    "commune",
    "region"
];

class LocalizationComponent extends Component {

    constructor(element) {
        super(element);

        if(!navigator.geolocation) {
            this.element.parentNode.removeChild(this.element);
            return;
        }

        this.input  = document.querySelector('[name="search"]');
        this.canOut = false;

        this.attachEvents();
    }

    attachEvents() {
        this.element.addEventListener('click', this.handleClick.bind(this), false);
        this.input.addEventListener('focus', this.handleFocus.bind(this), false);
        this.input.addEventListener('input', this.handleInput.bind(this), false);

        document.documentElement.addEventListener('click', this.handleClickOut.bind(this), false);
    }

    handleClick() {
        navigator.geolocation.getCurrentPosition(function(position) {
            let pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
            };

            let geocoder = new google.maps.Geocoder();
            let data     = {};

            geocoder.geocode({'location': pos}, function(results, status) {
                if (status === google.maps.GeocoderStatus.OK) {
                    if (results[0]) {
                        results[0].address_components.forEach((item) => {
                            if(item.types && item.types[0]) {
                                let key = item.types[0];

                                if(key == "administrative_area_level_1") key = "region";
                                if(key == "administrative_area_level_2") key = "departement";

                                if(~FIELDS.indexOf(key))
                                    data[key] = item.long_name;
                            }
                        });
                    }

                    if(!Object.keys(data).length) return;

                    FicheStore.SET_FILTER('search', (fiche) => {
                        let res = false;

                        for(var field in data) {
                            let value    = data[field];
                            let intValue = parseInt(value, 10);

                            if(fiche[field] && (~fiche[field].toLowerCase().indexOf(value) || (intValue && ~fiche[field].indexOf(intValue)))) res = true;
                        }

                        return res;
                    });
                }
            });
        });

        this.canOut = false;
        this.element.classList.remove('is-active');
    }

    handleFocus() {
        if(!this.canOut && document.querySelector('.autocomplete-value')) return;

        setTimeout(() => {
            this.canOut = true;
        }, 250);

        this.element.classList.add('is-active');
    }

    handleInput() {
        this.element.classList.remove('is-active');
    }

    handleClickOut(event) {
        if(this.canOut && event.target != this.element && !this.element.contains(event.target)) {
            this.canOut = false;
            this.element.classList.remove('is-active');
        }
    }
}

Component.register('.localization', LocalizationComponent);

export default LocalizationComponent;