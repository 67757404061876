/**
 * Created by baest on 30/09/2016.
 */
class ComponentList {

    constructor() {
        this.selectors = {};

        for(let selector in this.selectors) {
            if(document.querySelector(selector)) {
                Array.from(document.querySelectorAll(selector)).forEach((element) => {
                    try {
                        new this.selectors[selector](element);
                    } catch(err) {
                        console.trace(err);
                    }
                });
            }
        }

        this.attachEvents();
    }

    attachEvents() {
        this.observer = new MutationObserver(this.handleChange.bind(this));

        let observerConfig = {
            attributes: true,
            childList: true,
            subtree: true
        };

        // Node, config
        // In this case we'll listen to all changes to body and child nodes
        this.observer.observe(document.body, observerConfig);

    }

    handleChange(mutations) {
        if(window.isDragging && window.isDragging === true) return;

        mutations.forEach((mutation) => {
            for(let selector in this.selectors) {
                if(document.querySelector(selector)) {
                    Array.from(document.querySelectorAll(selector)).forEach((element) => {
                        for (let i = 0; i < mutation.addedNodes.length; i++) {
                            if (element === mutation.addedNodes[i]) {
                                try {
                                    new this.selectors[selector](element);
                                } catch(err) {
                                    console.error(err);
                                }
                            }
                        }
                    });
                }
            }
        });
    }

    register(selector, component) {
        if(!this.selectors) this.selectors = {};

        this.selectors[selector] = component;

        if(document.querySelector(selector)) {
            Array.from(document.querySelectorAll(selector)).forEach((element) => {
                try {
                    new this.selectors[selector](element);
                } catch(err) {
                    console.error(err);
                }
            });
        }
    }

}

export default new ComponentList();